const router_en = [
    {
      path: '/en/login',
      name:"Login",
      component: () => import('@/views/en/login/login.vue'),
    },
    {
      path: '/en/login/other',
      name:"otherLogin",
      component: () => import('@/views/en/login/others.vue'),
    },
    {
      path: '/en/code',
      name:"Code",
      component: () => import('@/views/en/login/code.vue'),
    },
    // 语言切换
    {
      path: '/en/language',
      name:"language",
      component: () => import('@/views/en/account/language.vue'),
    },
  
    // 首页  以及首页关联的子页面
  
    {
      path: '/en',
      name:"Home",
      meta: {keepAlive: true},
      component: () => import('@/views/en/home/index.vue'),
    },
    {
      path: '/en/mooby',
      name:"Mooby",
      meta: {keepAlive: true},
      component: () => import('@/views/en/mooby/mooby.vue'),
    },
    {
      path: '/en/welfare',
      name:"Wlfare",
      meta: {keepAlive: true},
      component: () => import('@/views/en/welfare/welfare.vue'),
    },
    {
      path: '/en/presale',
      name:"Presale",
      meta: {keepAlive: true},
      component: () => import('@/views/en/presale/presale.vue'),
    },
    {
      path: '/en/subsale',
      name:"Subsale",
      meta: {keepAlive: true},
      component: () => import('@/views/en/presale/subsale.vue'),
    },
    {
      path: '/en/gift',
      name:"Gift",
      meta: {keepAlive: true},
      component: () => import('@/views/en/gift/gift.vue'),
    },
    {
      path: '/en/better',
      name:"Better",
      meta: {keepAlive: true},
      component: () => import('@/views/en/better/list.vue')
    },
    {
      path: '/en/newProduct',
      name:"NewProduct",
      meta: {keepAlive: true},
      component: () => import('@/views/en/new-product/new.vue')
    },
    {
      path: '/en/subnew',
      name:"SubNew",
      meta: {keepAlive: true},
      component: () => import('@/views/en/new-product/subnew.vue')
    },
    {
      path: '/en/hotsale',
      name:"HotSale",
      meta: {keepAlive: true},
      component: () => import('@/views/en/home/hotsale.vue')
    },
    {
      path: '/en/subhot',
      name:"SubHot",
      meta: {keepAlive: true},
      component: () => import('@/views/en/home/subhot.vue')
    },
    {
      path: '/en/sublist',
      name:"SubList",
      meta: {keepAlive: true},
      component: () => import('@/views/en/gift/sub-list.vue')
    },
    
  
  
    // 搜索
    {
      path: '/en/search',
      name:"Search",
      meta: {keepAlive: true},
      component: () => import('@/views/en/search/index.vue'),
    },
  
  
    // 分类
  
    {
      path: '/en/classify',
      name:"Classify",
      meta: {keepAlive: true},
      component: () => import('@/views/en/classify/index.vue'),
    },
    {
      path: '/en/classify_two',
      name:"ClassifyTwo",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/classify/classify-two.vue'),
    },
    {
      path: '/en/goods_list',
      name:"GoodsList",
      meta: {keepAlive: true},
      component: () => import('@/views/en/goods/goods-list.vue'),
    },
  
    //  购物车
    {
      path: '/en/cart',
      name:"Cart",
      meta: { auth: true },
      component: () => import('@/views/en/cart/index.vue'),
    },
    //  满减包邮地区
    {
      path: '/en/city',
      name:"City",
      component: () => import('@/views/en/cart/city.vue'),
    },
    //  下单结算
    {
      path: '/en/payment',
      name:"payment",
      component: () => import('@/views/en/payment/index.vue'),
    },
    //  立即购买
    {
      path: '/en/buynow',
      name:"buynow",
      component: () => import('@/views/en/payment/buynow.vue'),
    },
    //  支付方式
    {
      path: '/en/pay',
      name:"pay",
      component: () => import('@/views/en/payment/pay.vue'),
    },
    //  支付方式
    {
      path: '/en/usPay',
      name:"pay",
      component: () => import('@/views/en/payment/usPay.vue'),
    },
    //  信用卡支付
    {
      path: '/en/pay/visa/:title',
      name:"Visamaster",
      component: () => import('@/views/en/payment/visaCard.vue'),
    },
    {
      path: '/en/pay/barcodepay/:title',
      name:"qrcode",
      component: () => import('@/views/en/payment/barcodepay.vue'),
    },
    //  支付状态
    {
      path: '/en/paystatus',
      name:"status",
      component: () => import('@/views/en/payment/status.vue'),
    },
  
    // 消息
    {
      path: '/en/service',
      name:"Service",
      component: () => import('@/views/en/message/service.vue'),
    },
    {
      path: '/en/msg',
      name:"Msg",
      meta: { auth: true },
      component: () => import('@/views/en/message/index.vue'),
    },
    {
      path: '/en/logistics',
      name:"Logistics",
      component: () => import('@/views/en/message/logistics.vue')
    },
    {
      path: '/en/activity',
      name:"Activity",
      component: () => import('@/views/en/message/activity.vue')
    },
    {
      path: '/en/chat',
      name:"Chat",
      component: () => import('@/views/en/message/chat.vue')
    },
    {
      path: '/en/customer',
      name:"customer",
      component: () => import('@/views/en/home/customer.vue')
    },
  
    // 个人中心
    {
      path: '/en/account',
      name:"Account",
      meta: { auth: true },
      component: () => import('@/views/en/account/account.vue'),
    },
    {
      path: '/en/change',
      name:"Change",
      component: () => import('@/views/en/account/change.vue')
    },
    {
      path: '/en/followed/list',
      name:"Followed",
      component: () => import('@/views/en/followed/followed.vue')
    },
    {
      path: '/en/favorite',
      name:"Favorite",
      component: () => import('@/views/en/favorite/favorite.vue')
    },
    {
      path: '/en/record',
      name:"Record",
      component: () => import('@/views/en/record/record.vue')
    },
    {
      path: '/en/address',
      name:"Address",
      component: () => import('@/views/en/address/list.vue')
    },
    {
      path: '/en/address/edit',
      name:"AddressEdit",
      component: () => import('@/views/en/address/edit.vue')
    },
    // 优惠券
    {
      path: '/en/coupon',
      name:"Coupon",
      component: () => import('@/views/en/coupon/index.vue')
    },
  
  
    // 合伙人
    {
      path: '/en/partner/type',
      name:"PartnerType",
      component: () => import('@/views/en/partner/type.vue')
    },
    {
      path: '/en/partner/application/:type',
      name:"Partner",
      component: () => import('@/views/en/partner/partner.vue')
    },
  
    
  
    // 商家
    {
      path: '/en/merchant/type',
      name:"MerchantType",
      component: () => import('@/views/en/merchant/type.vue')
    },
    {
      path: '/en/merchant/application/:type',
      name:"Merchant",
      component: () => import('@/views/en/merchant/application.vue')
    },
    {
      path: '/en/merchant/list',
      name:"MerchantList",
      meta: {keepAlive: true},
      component: () => import('@/views/en/merchant/list.vue')
    },
    {
      path: '/en/merchant/index/:id',
      name:"MerchantIndex",
      component: () => import('@/views/en/merchant/index.vue')
    },
    {
      path: '/en/merchant/goods/:id',
      name:"MerchantGoods",
      component: () => import('@/views/en/merchant/goods.vue')
    },
    {
      path: '/en/merchant/classify/:id',
      name:"MerchantClassify",
      component: () => import('@/views/en/merchant/classify.vue')
    },
    {
      path: '/en/merchant/goodsList',
      name:"MerchantClassifyGoods",
      component: () => import('@/views/en/merchant/classify-goods.vue')
    },
    {
      path: '/en/merchant/chat/:id',
      name:"MerchantChat",
      component: () => import('@/views/en/merchant/chat.vue')
    },
  
  
    // 钱包
    {
      path: '/en/wallet',
      name:"Wallet",
      component: () => import('@/views/en/wallet/wallet.vue'),
    },
    {
      path: '/en/wallet/cash',
      name:"WalletCash",
      component: () => import('@/views/en/wallet/cash.vue'),
    },
    {
      path: '/en/wallet/cardpackage',
      name:"Cardpackage",
      component: () => import('@/views/en/wallet/package-card.vue'),
    },
    {
      path: '/en/wallet/bankcard',
      name:"Bankcard",
      component: () => import('@/views/en/wallet/bank-card.vue'),
    },
    {
      path: '/en/wallet/record',
      name:"WalletRecord",
      component: () => import('@/views/en/wallet/record.vue'),
    },

    // 代理钱包
    {
      path: '/en/wallet-agent',
      name:"Agent wallet",
      component: () => import('@/views/en/wallet-agent/wallet.vue'),
    },
    {
      path: '/en/wallet-agent/record',
      name:"Agent wallet details",
      component: () => import('@/views/en/wallet-agent/record.vue'),
    },
    {
      path: '/en/wallet-agent/cash',
      name:"Agent withdrawal",
      component: () => import('@/views/en/wallet-agent/cash.vue'),
    },
    {
      path: '/en/wallet-agent/cardpackage',
      name:"Proxy card package",
      component: () => import('@/views/en/wallet-agent/package-card.vue'),
    },
    {
      path: '/en/wallet-agent/bankcard',
      name:"Agent Bank Card Information",
      component: () => import('@/views/en/wallet-agent/bank-card.vue'),
    },
    
    
    // 商品
    {
      path: '/en/goods/detail/:id',
      name:"GoodsDetail",
      component: () => import('@/views/en/goods/detail.vue')
    },
    {
      path: '/en/goods/comment/:id',
      name:"GoodsComment",
      component: () => import('@/views/en/comment/goods-comment.vue')
    },
    {
      path: '/en/comment/list',
      name:"CommentList",
      component: () => import('@/views/en/comment/list.vue')
    },
    {
      path: '/en/comment/submit',
      name:"CommentSubmit",
      component: () => import('@/views/en/comment/submit.vue')
    },
    {
      path: '/en/comment/detail',
      name:"CommentDetail",
      component: () => import('@/views/en/comment/detail.vue')
    },
  
  
    // 订单
    {
      path: '/en/order/:tab',
      name:"Order",
      component: () => import('@/views/en/order/list.vue'),
    },
    {
      path: '/en/order/detail/:id',
      name:"OrderDetail",
      component: () => import('@/views/en/order/detail.vue'),
    },
    // 取消订单
    {
      path: '/en/order/cancle/:id',
      name:"OrderCancle",
      component: () => import('@/views/en/order/cancle.vue'),
    },
  
    // 售后
    {
      path: '/en/aftersale/list',
      name:"Aftersale",
      component: () => import('@/views/en/aftersale/list.vue'),
    },
    {
      path: '/en/aftersale/select/:id',
      name:"SelectAftersale",
      component: () => import('@/views/en/aftersale/select.vue'),
    },
    {
      path: '/en/aftersale/submit',
      name:"AftersaleSubmit",
      component: () => import('@/views/en/aftersale/submit.vue'),
    },
    {
      path: '/en/aftersale/detail/:id',
      name:"AftersaleDetail",
      component: () => import('@/views/en/aftersale/detail.vue'),
    },
    {
      path: '/en/aftersale/address',
      name:"AftersaleAdress",
      component: () => import('@/views/en/aftersale/address.vue'),
    },
    {
      path: '/en/aftersale/logistics/:id',
      name:"AftersaleLogistics",
      component: () => import('@/views/en/aftersale/logistics.vue'),
    },
    {
      path: '/en/share',
      name:"Share",
      component: () => import('@/views/en/goods/share.vue'),
    },
  
   
    // 平台反馈 提交反馈
    {
      path: '/en/suggest',
      name:"Suggest",
      component: () => import('@/views/en/suggest/suggest.vue'),
    },
    {
      path: '/en/suggest/submit/:type',
      name:"SuggestSubmit",
      component: () => import('@/views/en/suggest/submit.vue')
    },
    
    
    // 设置  以及子页面
    {
      path: '/en/setting',
      name:"Setting",
      component: () => import('@/views/en/setting/setting.vue'),
    },
    {
      path: '/en/setting/user',
      name:"SettingUser",
      component: () => import('@/views/en/setting/user.vue')
    },
    {
      path: '/en/setting/security',
      name:"SettingSecurity",
      component: () => import('@/views/en/setting/security.vue'),
    },
    {
      path: '/en/setting/identity',
      name:"SettingIdentity",
      component: () => import('@/views/en/setting/check-identity.vue')
    },
    {
      path: '/en/setting/password',
      name:"SettingPassword",
      component: () => import('@/views/en/setting/edit-password.vue')
    },
    {
      path: '/en/setting/checkphone',
      name:"SettingPheckPhone",
      component: () => import('@/views/en/setting/check-phone.vue')
    },
    {
      path: '/en/setting/phone',
      name:"SettingPhone",
      component: () => import('@/views/en/setting/edit-phone.vue')
    },
    {
      path: '/en/setting/checkemail',
      name:"SettingCheckEmail",
      component: () => import('@/views/en/setting/check-email.vue')
    },
    {
      path: '/en/setting/email',
      name:"SettingEmail",
      component: () => import('@/views/en/setting/edit-email.vue')
    },
    {
      path: '/en/setting/bindemail',
      name:"SettingBindEmail",
      component: () => import('@/views/en/setting/bind-email.vue')
    },
    {
      path: '/en/setting/terms',
      name:"SettingtTerms",
      component: () => import('@/views/en/setting/terms.vue')
    },
    {
      path: '/en/setting/clause/use',
      name:"Terms of Use",
      component: () => import('@/views/en/setting/clause/use.vue')
    },
    {
      path: '/en/setting/clause/privacy',
      name:"Privacy",
      component: () => import('@/views/en/setting/clause/privacy.vue')
    },
    {
      path: '/en/setting/clause/returns',
      name:"returns",
      component: () => import('@/views/en/setting/clause/returns.vue')
    },
    {
      path: '/en/setting/clause/security',
      name:"security",
      component: () => import('@/views/en/setting/clause/security.vue')
    },
    {
      path: '/en/setting/clause/shipping',
      name:"Shipping",
      component: () => import('@/views/en/setting/clause/shipping.vue')
    },
    {
      path: '/en/setting/clause/pay',
      name:"Pay",
      component: () => import('@/views/en/setting/clause/pay.vue')
    },
    {
      path: '/en/setting/clause/about',
      name:"About",
      component: () => import('@/views/en/setting/clause/about.vue')
    },
    {
      path: '/en/search_order',
      name:"SearchOrder",
      component: () => import('@/views/en/account/phone.vue')
    },
    {
      path: '/en/offshelf',
      name:"该商品已下架",
      component: () => import('@/views/en/offshelf/offshelf.vue')
    },
    // 游客
    {
      path: '/en/guest/address',
      name:"Guest",
      component: () => import('@/views/en/address/guest.vue')
    },
    {
      path: '/nightmarketonline',
      name:"nightmarketonline",
      component: () => import('@/views/en/richmond/online.vue')
    },
    {
      path: '/nightmarketcoupons',
      name:"nightmarketcoupons",
      component: () => import('@/views/en/richmond/index.vue')
    },
    {
      path: '/richmond/coupon',
      name:"coupon",
      component: () => import('@/views/en/richmond/coupon.vue')
    },
    {
      path: '/richmond/charge',
      name:"charge",
      component: () => import('@/views/en/richmond/charge.vue')
    },
    {
      path: '/richmond/share',
      name:"share",
      component: () => import('@/views/en/richmond/share.vue')
    },
    {
      path: '/richmond/assistance',
      name:"assistance",
      component: () => import('@/views/en/richmond/assistance.vue')
    },
    {
      path: '/en/active',
      name:"active",
      meta: {keepAlive: true},
      component: () => import('@/views/en/active/active.vue')
    },
    {
      path: '/en/active/rule',
      name:"activeRule",
      component: () => import('@/views/en/active/rule.vue')
    },
    {
      path: '/en/landing/:id',
      name:"落地页活动",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/landing/landing.vue')
    },
    {
      path: '/en/bargain',
      name:"bargain",
      component: () => import('@/views/en/bargain/index.vue'),
    },
    {
      path: '/en/bargain/share',
      name:"bargainShare",
      component: () => import('@/views/en/bargain/share.vue')
    },
    {
      path: '/en/bargain/buy',
      name:"bargainBuy",
      component: () => import('@/views/en/bargain/buy.vue')
    },
    {
      path: '/en/help',
      name:"bargainHelp",
      meta: {keepAlive: true},
      component: () => import('@/views/en/bargain/help.vue')
    },
    {
      path: '/en/haggleList',
      name:"砍价商品列表",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/haggle/list.vue')
    },
    {
      path: '/en/haggle/:id',
      name:"平台砍价",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/haggle/index.vue')
    },
    {
      path: '/en/haggleDetail/:id',
      name:"平台砍价",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/haggle/detail.vue')
    },
    {
      path: '/en/myHaggle',
      name:"我的砍价",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/haggle/myHaggle.vue')
    },
    {
      path: '/en/haggleShare',
      name:"平台砍价分享",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/haggle/share.vue')
    },
    {
      path: '/en/haggleHelp',
      name:"平台砍价分享",
      // meta: {keepAlive: true},
      component: () => import('@/views/en/haggle/help.vue')
    },
    {
      path: '/en/supply',
      name:"开学季",
      meta: {keepAlive: true},
      component: () => import('@/views/en/supply/index.vue')
    },
    {
      path: '/en/blackFriday',
      name:"黑五",
      meta: {keepAlive: true},
      component: () => import('@/views/en/blackFriday/friday.vue')
    },
    {
      path: '/en/goddess',
      name:"女神节",
      meta: {keepAlive: true},
      component: () => import('@/views/en/active/divinity.vue')
    },
    {
      path: '/en/children',
      name:"儿童节",
      meta: {keepAlive: true},
      component: () => import('@/views/en/children/index.vue')
    },
]

export default router_en