import { Button, Tabbar, TabbarItem, Tab, Tabs, Swipe, SwipeItem, Lazyload, Grid, GridItem, Cell, CellGroup, Divider, Popup, Loading, Search, Dialog,

Notify, Tag, CountDown, Form, Field, Picker, SwipeCell, Toast, List, Checkbox, CheckboxGroup, Stepper, ActionSheet, Uploader, RadioGroup, Radio, Rate, PullRefresh,

Sticky, Icon, Slider, IndexBar, IndexAnchor, Switch, Sku, Overlay, Skeleton, ShareSheet, Col, Row, ImagePreview, Progress

} from 'vant'

const components = { Button, Tabbar, TabbarItem, Tab, Tabs, Swipe, SwipeItem, Lazyload, Grid, GridItem, Cell, CellGroup, Divider, Popup, Loading, Search, Dialog,

    Notify, Tag, CountDown, Form, Field, Picker, SwipeCell, Toast, List, Checkbox, CheckboxGroup, Stepper, ActionSheet, Uploader, RadioGroup, Radio, Rate, PullRefresh,

    Sticky, Icon, Slider, IndexBar, IndexAnchor, Switch, Sku, Overlay, Skeleton, ShareSheet, Col, Row, ImagePreview, Progress
}

const componentsHandler = {
    install(Vue){
      Object.keys(components).forEach(key => Vue.use(components[key]))
    }
  }
  
  export default componentsHandler
