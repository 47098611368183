const router_zh = [
    {
      path: '/zh/login',
      name:"登录",
      component: () => import('@/views/zh/login/login.vue'),
    },{
      path: '/zh/login/other',
      name:"其他登录",
      component: () => import('@/views/zh/login/others.vue'),
    },
    {
      path: '/zh/code',
      name:"验证码",
      component: () => import('@/views/zh/login/code.vue'),
    },
    // 首页  以及首页关联的子页面
    {
      path: '/',
      name:"主页",
      redirect: '/zh'
    },
    {
      path: '/zh',
      name:"中文首页",
      component: () => import('@/views/zh/home/index.vue'),
      meta: {keepAlive: true}
    },
    {
      path: '/zh/mooby',
      name:"自营专区",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/mooby/mooby.vue'),
    },
    {
      path: '/zh/welfare',
      name:"天天福利",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/welfare/welfare.vue'),
    },
    {
      path: '/zh/discount',
      name:"折扣专区",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/discount/index.vue'),
    },
    {
      path: '/zh/presale',
      name:"新品预售",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/presale/presale.vue'),
    },
    {
      path: '/zh/subsale',
      name:"预售内页",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/presale/subsale.vue'),
    },
    {
      path: '/zh/gift',
      name:"礼物专区",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/gift/gift.vue'),
    },
    {
      path: '/zh/better',
      name:"优选好货",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/better/list.vue')
    },
    {
      path: '/zh/newProduct',
      name:"新品首发",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/new-product/new.vue')
    },
    {
      path: '/zh/subnew',
      name:"新品内页",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/new-product/subnew.vue')
    },
    {
      path: '/zh/hotsale',
      name:"热销单品",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/home/hotsale.vue')
    },
    {
      path: '/zh/subhot/:id',
      name:"热销内页",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/home/subhot.vue')
    },
    
    // 搜索
    {
      path: '/zh/search',
      name:"搜索",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/search/index.vue'),
    },
  
    // 分类
    {
      path: '/zh/classify',
      name:"分类",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/classify/index.vue'),
    },
    {
      path: '/zh/subclassify',
      name:"二级分类",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/classify/subclassify.vue')
    },
    {
      path: '/zh/share',
      name:"分享",
      component: () => import('@/views/zh/goods/share.vue'),
    },
  
    //  购物车
    {
      path: '/zh/cart',
      name:"购物车",
      meta: { auth: true },
      component: () => import('@/views/zh/cart/index.vue'),
    },
    //  满减包邮地区
    {
      path: '/zh/city',
      name:"city",
      component: () => import('@/views/zh/cart/city.vue'),
    },
     //  购物车结算
     {
      path: '/zh/payment',
      name:"购物车结算",
      component: () => import('@/views/zh/payment/index.vue'),
    },
    //  立即购买
    {
      path: '/zh/buynow',
      name:"立即购买",
      component: () => import('@/views/zh/payment/buynow.vue'),
    },
    //  支付方式
    {
      path: '/zh/pay',
      name:"支付方式",
      component: () => import('@/views/zh/payment/pay.vue'),
    },
    //  支付方式
    {
      path: '/zh/usPay',
      name:"pay",
      component: () => import('@/views/zh/payment/usPay.vue'),
    },
    //  信用卡支付
    {
      path: '/zh/pay/visa/:title',
      name:"信用卡支付",
      component: () => import('@/views/zh/payment/visaCard.vue'),
    },
    {
      path: '/zh/pay/barcodepay/:title',
      name:"扫码支付",
      component: () => import('@/views/zh/payment/barcodepay.vue'),
    },
    //  支付状态
    {
      path: '/zh/paystatus',
      name:"支付状态",
      component: () => import('@/views/zh/payment/status.vue'),
    },
  
    // 消息
    {
      path: '/zh/msg',
      name:"消息",
      meta: { auth: true },
      component: () => import('@/views/zh/message/index.vue'),
    },
    {
      path: '/zh/logistics',
      name:"交易物流",
      component: () => import('@/views/zh/message/logistics.vue')
    },
    {
      path: '/zh/activity',
      name:"活动优惠",
      component: () => import('@/views/zh/message/activity.vue')
    },
    {
      path: '/zh/chat',
      name:"聊天",
      component: () => import('@/views/zh/message/chat.vue')
    },
    {
      path: '/zh/customer',
      name:"客服",
      component: () => import('@/views/zh/home/customer.vue')
    },
  
    // 个人中心
    {
      path: '/zh/account',
      name:"我的",
      meta: { auth: true, keepAlive: true },
      component: () => import('@/views/zh/account/account.vue'),
    },
    {
      path: '/zh/boon',
      name: '活动福利',
      component: () => import('@/views/zh/account/boon.vue'),
    },
    {
      path: '/zh/followed/list',
      name:"我的关注",
      component: () => import('@/views/zh/followed/followed.vue')
    },
    {
      path: '/zh/favorite',
      name:"我的收藏",
      component: () => import('@/views/zh/favorite/favorite.vue')
    },
    {
      path: '/zh/record',
      name:"足迹",
      component: () => import('@/views/zh/record/record.vue')
    },
    {
      path: '/zh/coupon',
      name:"优惠券",
      component: () => import('@/views/zh/coupon/index.vue')
    },
    {
      path: '/zh/address',
      name:"我的地址",
      component: () => import('@/views/zh/address/list.vue')
    },
    {
      path: '/zh/address/edit',
      name:"编辑地址",
      component: () => import('@/views/zh/address/edit.vue')
    },
  
    // 合伙人
    {
      path: '/zh/partner',
      name:"合伙人申请",
      component: () => import('@/views/zh/partner/partner.vue')
    },
  
    // 商家
    {
      path: '/zh/merchant/application',
      name:"商家入驻申请",
      component: () => import('@/views/zh/merchant/application.vue')
    },
    {
      path: '/zh/merchant/list',
      name:"优选商家",
      component: () => import('@/views/zh/merchant/list.vue')
    },
    {
      path: '/zh/merchant/index/:id',
      name:"商家主页",
      component: () => import('@/views/zh/merchant/index.vue')
    },
    {
      path: '/zh/merchant/goods/:id',
      name:"商家商品",
      component: () => import('@/views/zh/merchant/goods.vue')
    },
    {
      path: '/zh/merchant/classify/:id',
      name:"商家分类",
      component: () => import('@/views/zh/merchant/classify.vue')
    },
    {
      path: '/zh/merchant/goodsList',
      name:"商家分类商品",
      component: () => import('@/views/zh/merchant/goods-list.vue')
    },
    {
      path: '/zh/merchant/chat/:id',
      name:"商家客服",
      component: () => import('@/views/zh/merchant/chat.vue')
    },
  
    // 钱包
    {
      path: '/zh/wallet',
      name:"钱包",
      component: () => import('@/views/zh/wallet/wallet.vue'),
    },
    {
      path: '/zh/wallet/record',
      name:"钱包明细",
      component: () => import('@/views/zh/wallet/record.vue'),
    },
    {
      path: '/zh/wallet/cash',
      name:"提现",
      component: () => import('@/views/zh/wallet/cash.vue'),
    },
    {
      path: '/zh/wallet/cardpackage',
      name:"卡包",
      component: () => import('@/views/zh/wallet/package-card.vue'),
    },
    {
      path: '/zh/wallet/bankcard',
      name:"银行卡信息",
      component: () => import('@/views/zh/wallet/bank-card.vue'),
    },

    // 代理钱包
    {
      path: '/zh/wallet-agent',
      name:"代理钱包",
      component: () => import('@/views/zh/wallet-agent/wallet.vue'),
    },
    {
      path: '/zh/wallet-agent/record',
      name:"代理钱包明细",
      component: () => import('@/views/zh/wallet-agent/record.vue'),
    },
    {
      path: '/zh/wallet-agent/cash',
      name:"代理提现",
      component: () => import('@/views/zh/wallet-agent/cash.vue'),
    },
    {
      path: '/zh/wallet-agent/cardpackage',
      name:"代理卡包",
      component: () => import('@/views/zh/wallet-agent/package-card.vue'),
    },
    {
      path: '/zh/wallet-agent/bankcard',
      name:"代理银行卡信息",
      component: () => import('@/views/zh/wallet-agent/bank-card.vue'),
    },
    
    // 商品
    {
      path: '/zh/goods/detail/:id',
      name:"商品详情",
      component: () => import('@/views/zh/goods/detail.vue')
    },
    {
      path: '/zh/goods-list/:id',
      name:"分类商品列表",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/goods/goods-list.vue')
    },
    {
      path: '/zh/goods/comment/:id',
      name:"商品评价",
      component: () => import('@/views/zh/comment/goods-comment.vue')
    },
    {
      path: '/zh/comment/list',
      name:"评价列表",
      component: () => import('@/views/zh/comment/list.vue')
    },
    {
      path: '/zh/comment/submit',
      name:"评价",
      component: () => import('@/views/zh/comment/submit.vue')
    },
    {
      path: '/zh/comment/detail',
      name:"评价详情",
      component: () => import('@/views/zh/comment/detail.vue')
    },
  
    // 订单
    {
      path: '/zh/order/:tab',
      name:"订单",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/order/list.vue'),
    },
    {
      path: '/zh/order/detail/:id',
      name:"订单详情",
      component: () => import('@/views/zh/order/detail.vue'),
    },
  
    // 售后
    {
      path: '/zh/aftersale/list',
      name:"售后列表",
      component: () => import('@/views/zh/aftersale/list.vue'),
    },
    {
      path: '/zh/aftersale/select/:id',
      name:"选择售后商品",
      component: () => import('@/views/zh/aftersale/select.vue'),
    },
    {
      path: '/zh/aftersale/submit',
      name:"提交售后申请",
      component: () => import('@/views/zh/aftersale/submit.vue'),
    },
    {
      path: '/zh/aftersale/detail/:id',
      name:"售后详情",
      component: () => import('@/views/zh/aftersale/detail.vue'),
    },
    {
      path: '/zh/aftersale/logistics/:id',
      name:"填写发货单",
      component: () => import('@/views/zh/aftersale/logistics.vue'),
    },
  
    // 平台反馈 提交反馈
    {
      path: '/zh/suggest',
      name:"平台反馈",
      component: () => import('@/views/zh/suggest/suggest.vue'),
    },
    {
      path: '/zh/suggest/submit/:type',
      name:"提交反馈",
      component: () => import('@/views/zh/suggest/submit.vue')
    },
    
    // 设置  以及子页面
    {
      path: '/zh/setting',
      name:"设置",
      component: () => import('@/views/zh/setting/setting.vue'),
    },
    {
      path: '/zh/setting/user',
      name:"个人信息",
      component: () => import('@/views/zh/setting/user.vue')
    },
    {
      path: '/zh/setting/security',
      name:"账户安全",
      component: () => import('@/views/zh/setting/security.vue'),
    },
    {
      path: '/zh/setting/identity',
      name:"身份认证",
      component: () => import('@/views/zh/setting/check-identity.vue')
    },
    {
      path: '/zh/setting/password',
      name:"修改密码",
      component: () => import('@/views/zh/setting/edit-password.vue')
    },
    {
      path: '/zh/setting/checkphone',
      name:"验证手机号",
      component: () => import('@/views/zh/setting/check-phone.vue')
    },
    {
      path: '/zh/setting/phone',
      name:"绑定新手机号",
      component: () => import('@/views/zh/setting/edit-phone.vue')
    },
    {
      path: '/zh/setting/checkemail',
      name:"验证邮箱",
      component: () => import('@/views/zh/setting/check-email.vue')
    },
    {
      path: '/zh/setting/email',
      name:"修改邮箱",
      component: () => import('@/views/zh/setting/edit-email.vue')
    },
    {
      path: '/zh/setting/bindemail',
      name:"绑定邮箱",
      component: () => import('@/views/zh/setting/bind-email.vue')
    },
    {
      path: '/zh/setting/terms',
      name:"条款",
      component: () => import('@/views/zh/setting/terms.vue')
    },
    {
      path: '/zh/setting/clause/use',
      name:"使用条款",
      component: () => import('@/views/zh/setting/clause/use.vue')
    },
    {
      path: '/zh/setting/clause/pay',
      name:"支付条款",
      component: () => import('@/views/zh/setting/clause/pay.vue')
    },
    {
      path: '/zh/setting/clause/privacy',
      name:"隐私条款",
      component: () => import('@/views/zh/setting/clause/privacy.vue')
    },
    {
      path: '/zh/setting/clause/returns',
      name:"退货条款",
      component: () => import('@/views/zh/setting/clause/returns.vue')
    },
    {
      path: '/zh/setting/clause/security',
      name:"安全条款",
      component: () => import('@/views/zh/setting/clause/security.vue')
    },
    {
      path: '/zh/setting/clause/shipping',
      name:"邮寄条款",
      component: () => import('@/views/zh/setting/clause/shipping.vue')
    },
    {
      path: '/zh/setting/clause/about',
      name:"关于我们",
      component: () => import('@/views/zh/setting/clause/about.vue')
    },
    {
      path: '/zh/search_order',
      name:"手机号查单",
      component: () => import('@/views/zh/account/phone.vue')
    },
    
    // 游客
    {
      path: '/zh/guest/address',
      name:"游客登录添加地址",
      component: () => import('@/views/zh/address/guest.vue')
    },
    // 游客
    {
      path: '/zh/orderDone',
      name:"确认送达",
      component: () => import('@/views/zh/order/orderDone.vue')
    },
    {
      path: '/zh/offshelf',
      name:"该商品已下架",
      component: () => import('@/views/zh/offshelf/offshelf.vue')
    },
    // // 抽奖任务
    // {
    //   path: '/zh/mission',
    //   name:"获取抽奖码",
    //   // meta: {keepAlive: true},
    //   component: () => import('@/views/zh/mission/mission.vue')
    // },
    // // 中奖记录
    // {
    //   path: '/zh/winrecord',
    //   name:"中奖记录",
    //   meta: {keepAlive: true},
    //   component: () => import('@/views/zh/mission/win-record.vue')
    // },
    // // 分享图
    // {
    //   path: '/zh/friendsShare',
    //   name:"中奖记录",
    //   meta: {keepAlive: true},
    //   component: () => import('@/views/zh/mission/share.vue')
    // },
    // // 抽奖任务
    // {
    //   path: '/zh/zbwelfare',
    //   name:"直播福利",
    //   component: () => import('@/views/zh/mission/welfare.vue')
    // },
    // 分享图
    // {
    //   path: '/zh/helping',
    //   name:"分享登录",
    //   component: () => import('@/views/zh/helpinglogin/helpinglogin.vue')
    // },
    // 公众号二维码图
    {
      path: '/liveshow',
      name:"公众号二维码",
      component: () => import('@/views/zh/qrcode/qrcode.vue')
    },
    {
      path: '/banners',
      name:"电信购卡",
      component: () => import('@/views/zh/banners/banners.vue')
    },
    {
      path: '/zh/active',
      name:"首页主题活动",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/active/active.vue')
    },
    {
      path: '/zh/active/rule',
      name:"首页主题活动规则",
      component: () => import('@/views/zh/active/rule.vue')
    },
    {
      path: '/zh/landing/:id',
      name:"落地页活动",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/landing/landing.vue')
    },
    {
      path: '/zh/bargain',
      name:"夜市套票砍价",
      component: () => import('@/views/zh/bargain/index.vue'),
    },
    {
      path: '/zh/bargain/share',
      name:"分享好友砍价",
      component: () => import('@/views/zh/bargain/share.vue')
    },
    {
      path: '/zh/bargain/buy',
      name:"购买",
      component: () => import('@/views/zh/bargain/buy.vue')
    },
    {
      path: '/zh/help',
      name:"好友助力",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/bargain/help.vue')
    },
    {
      path: '/zh/haggleList',
      name:"砍价商品列表",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/haggle/list.vue')
    },
    {
      path: '/zh/haggle/:id',
      name:"平台砍价",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/haggle/index.vue')
    },
    {
      path: '/zh/haggleDetail/:id',
      name:"平台砍价",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/haggle/detail.vue')
    },
    {
      path: '/zh/myHaggle',
      name:"我的砍价",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/haggle/myHaggle.vue')
    },
    {
      path: '/zh/haggleShare',
      name:"平台砍价分享",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/haggle/share.vue')
    },
    {
      path: '/zh/haggleHelp',
      name:"平台砍价分享",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/haggle/help.vue')
    },
    // {
    //   path: '/live',
    //   name:"直播砍价落地页",
    //   // meta: {keepAlive: true},
    //   component: () => import('@/views/zh/live/list.vue')
    // },
    // {
    //   path: '/liveMy',
    //   name:"我的砍价列表",
    //   // meta: {keepAlive: true},
    //   component: () => import('@/views/zh/live/myJoin.vue')
    // },
    // {
    //   path: '/liveHaggle/:id',
    //   name:"直播砍价商品详情",
    //   // meta: {keepAlive: true},
    //   component: () => import('@/views/zh/live/index.vue')
    // },
    // {
    //   path: '/liveDetail/:id',
    //   name:"砍价商品sku详情",
    //   // meta: {keepAlive: true},
    //   component: () => import('@/views/zh/live/detail.vue')
    // },
    // {
    //   path: '/liveShare',
    //   name:"平台砍价分享",
    //   // meta: {keepAlive: true},
    //   component: () => import('@/views/zh/live/share.vue')
    // },
    // {
    //   path: '/liveHelp',
    //   name:"帮忙砍价",
    //   // meta: {keepAlive: true},
    //   component: () => import('@/views/zh/live/help.vue')
    // },
    {
      path: '/zh/scratch',
      name:"刮刮乐",
      // meta: {keepAlive: true},
      component: () => import('@/views/zh/scratch/index.vue')
    },
    {
      path: '/zh/supply',
      name:"开学季",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/supply/index.vue')
    },
    {
      path: '/zh/blackFriday',
      name:"黑五",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/blackFriday/friday.vue')
    },
    {
      path: '/zh/goddess',
      name:"女神节",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/active/divinity.vue')
    },
    {
      path: '/zh/children',
      name:"儿童节",
      meta: {keepAlive: true},
      component: () => import('@/views/zh/children/index.vue')
    },
]

export default router_zh