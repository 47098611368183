import Vue from 'vue'
import Vuex from 'vuex'
import { cartCount } from './../api/zh/cart'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {

    load: false,
    // imgPreUrl: 'https://file.moobyyoho.com/',
    // ossUrl: 'https://moobyyoho.oss-us-west-1.aliyuncs.com/',
    imgPreUrl: '',
    ossUrl: '',
    jsUrl: '',
    city: null,

    // 默认头像
    avatar:require('./../assets/account/avatar.png'),

    // 商家主页店铺信息
    merchant: { pic_url: '', title: '', collect_num: ''},

    cart: 0,

    isMiniProgram: false,
    // offsetTop: 0
    payStatus: ''
  },
  mutations: {
    // set_night_offset(state, val){
    //   // 设置进入微信时jssdk所需的URL
    //   state.offsetTop = val
    // },
    set_url(state, url){
      // 设置进入微信时jssdk所需的URL
      state.jsUrl = url
    },
    // 首页 loading
    load(state) {
      state.load = true
    },
    unload(state) {
      state.load = false
    },
    // 购物车数量
    addCart(state, num = 1) {
      state.cart += num
    },
    delCart(state, num = 1) {
      state.cart -= num
      if(state.cart <= 0) {
        state.cart = 0
      }
    },

  },

  actions: {
    getCart(context) {
      cartCount().then(res => {
        res && (context.state.cart = res.data)
      }) 
    }
  }
})

export default store;

