import Vue from 'vue'
import App from './App.vue'
import FastClick from './untils/js/fastclick'
import router from './router'
import store from './store'
import vantCompoents from './untils/js/vant'
import './untils/css/public.less'
import {Checkbox,CheckboxButton,CheckboxGroup} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import LetItSnow from 'vue-let-it-snow';
Vue.use(LetItSnow);
Vue.use(vantCompoents);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
import { Collapse, CollapseItem } from 'vant';

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.config.productionTip = false

// import '@/icons' // icon

if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function() {
      FastClick.attach(document.body);
  }, false);
}

FastClick.prototype.focus = function (targetElement) {
  targetElement.focus();
};
// 重新写入 toFixed 方法
Number.prototype.toFixed = function (n) {

  if (n > 20 || n < 0) {
      throw new RangeError('toFixed() digits argument must be between 0 and 20');
  }
  const number = this;
  if (isNaN(number) || number >= Math.pow(10, 21)) {
      return number.toString();
  }

  if (typeof (n) == 'undefined' || n == 0) {
      return (Math.round(number)).toString();
  }
  let result = number.toString();
  const arr = result.split('.');
  // 整数的情况
  if (arr.length < 2) {
      result += '.';
      for (let i = 0; i < n; i += 1) {
          result += '0';
      }
      return result;
  }
  const integer = arr[0];
  const decimal = arr[1];
  if (decimal.length == n) {
      return result;
  }

  if (decimal.length < n) {
      for (let i = 0; i < n - decimal.length; i += 1) {
          result += '0';
      }
      return result;
  }
  result = integer + '.' + decimal.substr(0, n);
  const last = decimal.substr(n, 1);
  // 四舍五入，转换为整数再处理，避免浮点数精度的损失
  if (parseInt(last, 10) >= 5) {
      const x = Math.pow(10, n);
      result = (Math.round((parseFloat(result) * x)) + 1) / x;
      result = result.toFixed(n);
  }
  return result;
}

// 全局混入
Vue.mixin({
  mounted() {
    // 结束加载动画
    setTimeout(() => {
      document.body.style.setProperty('--loadMooby', 'none')
    }, 100)
  }
})
// /注册一个全局前置守卫,确保要调用 next 方法，否则钩子就不会被 resolved
// router.beforeEach((to, from, next) => {
//     //判断该页面有channel
//     console.log(to,from)
//     next()
// })

// 手势插件
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'

Vue.use(AlloyFingerPlugin,{
  AlloyFinger
})

// 视频插件
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
