
import axios from 'axios'
import router from '@/router'
import store from '@/store/index.js'
import { Notify } from 'vant'
axios.defaults.timeout = 1000000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'


const service = axios.create({
    baseURL: 'https://mapi.moobyyoho.com',
    timeout: 200000
  })
// 环境的切换
// if (process.env.NODE_ENV == 'development') { 
//     axios.defaults.baseURL = 'https://mapi.moobyyoho.com'
// }else{
//     axios.defaults.baseURL = 'https://mapi.moobyyoho.com'
// }

// 请求拦截器
service.interceptors.request.use(
    config => {
        config.baseURL = 'https://mapi.moobyyoho.com'
        if(localStorage.getItem('token')) {
            config.headers['MoobyYoho-Access-Token'] = localStorage.getItem('token')
        }
        if (router.app.$route.fullPath.split('/')[1].substr(0, 2) == 'ri') {
            config.headers['MoobyYoho-Lang'] = 'en'
        } else if (router.app.$route.fullPath.split('/')[1].substr(0, 4) == 'live') {
            config.headers['MoobyYoho-Lang'] = 'zh'
        }
        else
        config.headers['MoobyYoho-Lang'] = router.app.$route.fullPath.split('/')[1].substr(0, 2) || 'zh'
        return config
    },
    error => { 
        store.commit('unload')
        return Promise.error(error)
    }
)

// 响应拦截器
service.interceptors.response.use(response => {  
    if (response.status === 200) {
        // 判断状态码 过滤某些信息
        if(response.data.code == 20000){
            return  response
        } else if (response.data.code == 500101) {
            let lang = router.app.$route.path.split('/')
            Notify({ type: 'warning', message: response.data.msg, onClose() {
                router.push({path:`/${lang[1]}/setting/security`})
            }})
            return  {}
        }else if(response.data.status == 1){
            return  response
        }else if(response.data.code == 20001){
            let lang = router.app.$route.path.split('/')
            if (lang[1] == "richmond") {
                lang.splice(1,1,'en')
            }
            if(lang[1].substr(0,4) == 'live') {
                lang.splice(1,1,'zh')
            }
                // router.push({path:"/404"})
            localStorage.removeItem('token')
            router.push({path:`/${lang[1]}/login` })
            return  {}
        }else if( [20002,20003].includes(response.data.code) ){
            Notify({ type: 'warning', message: response.data.msg, onOpened() {
                // router.push({path:"/404"})
            }})
            return  {}
        }else if( response.data.code == 20004 ){
            //   Notify({ type: 'warning', message: response.data.msg});
            return  {}
        }else if( response.data.code == 20005 ){
            Notify({ type: 'warning', message: response.data.msg })
            return  {}
        }else if( response.data.code == 20006 ){
            Notify({ type: 'warning', message: response.data.msg })
            return  {}
        }else if( response.data.code == 40004 ){
             let lang = router.app.$route.path.split('/')
             router.push({path: `/${lang[1]}/offshelf` })
             return  {}
        }else if ( response.data.code == 500201 ) {
            Notify({ type: 'warning', message: response.data.msg })
            return  response
        }else {
            if(router.app.$route.path.split('/').length > 1 && router.app.$route.path.split('/')[1] == 'en') {
                Notify({ type: 'warning', message: 'Request Failed.Please check the info again and try again later'})
            } else {
                // Notify({ type: 'warning', message: '请求失败,请检查信用卡信息，稍后重试'})
            }
        }
    }
}, error => {
    if(router.app.$route.path.split('/').length > 1 && router.app.$route.path.split('/')[1] == 'en') {
        Notify({ type: 'warning', message: 'There is something wrong with the network. Please try again later'})
    } else {
        Notify({ type: 'warning', message: '网络出小差了，请稍后重试'})
    }
    
    store.commit('unload')
    return Promise.reject(error)
})


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){ 
    return new Promise((resolve, reject) =>{ 
        service.get(url,{ params:params }).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data) 
        }) 
    })
}


/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param {Object} config [请求头设置信息] 
 */
export function post(url, params, config) {
    return new Promise((resolve, reject) => {
        service.post(url, params, config).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data)
        })
    })
}



/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        service.put(url,params).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data)
        })
    })
}


/** 
 * delete方法，对应delete请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function del(url, params) {
    return new Promise((resolve, reject) => {
        service.delete(url,params).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data)
        })
    })
}


/** 
 * 图片上传
 */
export function imgUpload(params) {
    return new Promise((resolve, reject) => {
        service.post('/pic/upload',params).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data)
        })
    })
}

/** 
 * 图片删除
 */
export function imgDelete(id) {
    return new Promise((resolve, reject) => {
        service.post(`pic/delpic/${id}`).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err.data)
        })
    })
}