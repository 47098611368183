/** 
 *   购物车
 */
import { get, post, put, del } from '@/untils/js/axios.js'

// 推荐商品
export const getCartRemGoods = params => get('/goods/recommend/index', params)

// 购物车数据--旧
// export const getCart = params => get('/procart/list', params)

// 加入购物车--旧
// export const addCart = data => post('/procart/addcart', data)

// 购物车更改数量--旧
// export const updateCart = (id,data) => put(`/procart/updatecart/${id}`, data)

// 删除购物车--旧
// export const delCart = data => del(`/procart/delcart/${data.ids}`)

// 购物车数据--新
export const getCart = params => get('/user/cart', params)

// 加入购物车--新
export const addCart = data => post('/user/cart', data)

// 购物车更改数量--新
export const updateCart = (id,data) => put(`/user/cart/${id}`, data)

// 购物车修改规格--新
export const updateSku = (id,data) => put(`/user/cart/update/barcode/${id}`, data)

// 删除购物车--新
export const delCart = data => del(`/user/cart/${data.ids}`)

// 购物车结算
export const subCart = data => post('/procart/subcart', data)

// 确认订单 信息
export const order = data => get('/order/confirm', data)

// 获取订单可用优惠券
export const coupon = data => get('/coupon_order_list', data)

// 生成订单 去支付
export const subOrder = data => post('/order/placeorder', data)


// 信用卡支付
export const visa_pay = data => post('/bambora/pay', data)

// 申请售后
export const aftersale = data => post('/aftersale/applyfor', data)



// 购物车 数量
export const cartCount = params => get('/procart/count', params)

// 购物车  清空无效商品
export const del_invalid = params => del('/user/cart/delete/all', params)