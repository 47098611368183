<template>
  <div id="app"  :class="isPc?'isPc':''">
    <transition name="van-fade" v-if="!isBeta">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </transition>

    <transition name="van-fade" v-if="!isBeta">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition>

    <!-- 遮罩加载 -->
    <van-overlay :show="$store.state.load" class="van-overlay-load">
      <div class="load-bg"></div>
      <div class="load-mooby loading-mooby">
        <!-- <div class="queue" v-if="$store.state.payStatus == 1">排队购买中，请稍等...</div> -->
        <img src="@/assets/loading.png" alt="">
        <div class="pswp__preloader__icn">
          <div class="pswp__preloader__cut">
            <div class="pswp__preloader__donut"></div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="isBeta" style="background-color: transparent;z-index:999999">
      <div class="beta-box">
        <img src="@/assets/beta_zh.png" alt="" v-if="lang == 'zh'">
        <img src="@/assets/beta_en.png" alt="" v-else>
      </div>
    </van-overlay>
    </div>
</template>

<script>
/* eslint-disable */
import { getLanguage, _isPC } from '@/untils/js/common.js'
export default {
  name: 'App',
  data() {
    return {
      isBeta: false,
      lang: 'zh',
      isPc: false
    }
  },
  watch: {
    '$route.path'(val) {
      if(val.split('/')[1] == 'zh') {
        this.lang = 'zh'
      } else {
        this.lang = 'en'
      }

      if(this.$route.query.beta == 1) {
        this.isBeta = false
        localStorage.setItem('beta', 1)
      }
    }
  },
  methods: {
    isWxMp() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') { //判断是否是微信环境
        //微信环境
        if (ua.match(/miniProgram/i) == 'miniprogram') {
          // 小程序环境下逻辑 
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i=0;i<vars.length;i++) {
              let pair = vars[i].split("=");
              if(pair[0] == variable){return pair[1];}
      }
      return(false);
    }
  },
  created() {
    // 检测小程序参数
    let isMiniProgram = this.getQueryVariable('isminiprogram')
    let path          = this.getQueryVariable('path')
    // // 小程序暂时封闭
    // if(this.isWxMp()) {
    //   // 小程序  显示封闭图层
    //   this.isBeta = true
    // } else {
    //   this.isBeta = false
    // }
    let Personal = this.getQueryVariable('uid')
    if (Personal) {
      localStorage.setItem('Personal',Personal)
    }
    if(isMiniProgram && isMiniProgram == 1 && this.isWxMp()) {
      // 从小程序进入
      this.$store.state.isMiniProgram = true
      localStorage.setItem('isMiniProgram', 1)
      
      if(path) {
        this.$router.push('/?isminiprogram=1')
        // 小程序直播活动连接处理
        this.$router.push( path.replace(/@/g, '?').replace(/\!/g, '='));
      } 
    } else {
      this.$store.state.isMiniProgram = false
      localStorage.setItem('isMiniProgram', null)
      // 判断是否是pc端
      if(_isPC()) {
        // 跳转PC端
        let isPc = this.getQueryVariable('href')
        console.log(isPc)
        let sessionIspc = sessionStorage.getItem('isPc')
        if (isPc == 'pc' || sessionIspc){
          this.isPc = true
          sessionStorage.setItem('isPc', true)
        }else{
          // this.isPc = true
          // window.location.href = "https://pc.moobyyoho.com"
        }
        if(!document.body.scrollWidth) {
            document.body.style.width  = document.body.clientWidth + 30  + 'px';
            document.body.style.height  = document.body.clientHeight / 0.35 + 'px';
         } else {
            document.body.style.width  = document.documentElement.clientWidth + 30 + 'px';
            document.body.style.height  = document.documentElement.clientHeight / 0.35 + 'px';
         }
      }
    }
    // 清除 砍价活动吉利
    localStorage.removeItem('bargain')
    // 进入时 清除位置记录
    localStorage.removeItem('scrollTop')
    // 首次加载 中英文跳转
    let lang = localStorage.getItem('lang')
    if(!lang) {
      lang = getLanguage()
      if (window.location.href.indexOf('/en') != -1) {
          localStorage.setItem('lang', 'en')
      }
      // window.location.href.indexOf('/live') != -1 || 
      if (window.location.href.indexOf('/zh') != -1) {
          localStorage.setItem('lang', 'zh')
      }
      if(lang == 'zh' && window.location.href.indexOf('/en') == -1) {
        localStorage.setItem('lang', 'zh')
        
        if(window.location.href.indexOf('/coupon') != -1) {
          this.$router.push('/coupon')
          //  && window.location.href.indexOf('/nightmarketonline') == -1 && window.location.href.indexOf('/live') == -1
        }else if(window.location.href.indexOf('/mid_autummn') != -1) {
          this.$router.push('/mid_autummn')
        }else if (window.location.href.indexOf('/zh') == -1 && !path) {
          this.$router.push('/zh')
        }
      } else {
        localStorage.setItem('lang', 'en')
        if(window.location.href.indexOf('/coupon') != -1) {
          this.$router.push('/coupon')
        }else if(window.location.href.indexOf('/mid_autummn') != -1) {
          this.$router.push('/mid_autummn')
        }else if (window.location.href.indexOf('/en') == -1 && !path) {
          this.$router.push('/en')
        }
      }
    }


    if (sessionStorage.getItem("store") ) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload",()=>{
        sessionStorage.setItem("store",JSON.stringify(this.$store.state))
        // 清空保存的滚动位置信息
        window.localStorage.setItem('scrollTop', JSON.stringify({}))
    })
    console.info(`%c
    ╔╦╗╔═╗╔═╗╔╗ ╦ ╦╦ ╦╔═╗╦ ╦╔═╗%c
    ║║║║ ║║ ║╠╩╗╚╦╝╚╦╝║ ║╠═╣║ ║%c
    ╩ ╩╚═╝╚═╝╚═╝ ╩  ╩ ╚═╝╩ ╩╚═╝`,'color:#7728de','color:#3328de','color:#1462ce')
  },
  mounted() {
    const that = this
    window.onresize = () => {
        return (() => {
          if (_isPC()) {
            if(!document.body.scrollWidth) {
              document.body.style.width  = document.body.clientWidth + 30  + 'px';
              document.body.style.height  = document.body.clientHeight / 0.35 + 'px';
            } else {
              document.body.style.width  = document.documentElement.clientWidth + 30 + 'px';
              document.body.style.height  = document.documentElement.clientHeight / 0.35 + 'px';
            }
          }
        })()
    };
    document.ondragstart = function() {
          return false;
    };
  }
}
</script>

<style lang="less">
  .fixed {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    .guide {
      width: 250px;
      height: 175px;
      background-image: url("./assets/market/Group937.png");
      background-size: 100% 100%;
      position: relative;
      left: 108px;
      top: 75px;
    }
    .fixed_white {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #fff;
      background-image: url("./assets/market/2020_1.png");
      background-size: 85% 85%;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 999;
      position: absolute;
      left: 146px;
      top: 75px;
    }
    .richmondShow {
      animation: richomdShow 1s;
    }
    @keyframes richomdShow {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(0.6);
      }
    }
    .fixed_btn {
      width: 200px;
      margin: auto;
      position: relative;
      top: 30%;
      transform: translateY(-50%);
      img {
        width: 100%;
      }
    }
  }
   @media  screen and (min-width: 1280px) {
      body {
        position: relative;
        top: 0;
        transform: scale(0.35, 0.35);
        transform-origin: top;
        margin: 0;
        padding: 0;
        background-color: #fff !important;
        font-size: 16px;
        height: 285vh !important;
      }
      .fixed_coupon {
        bottom: 10vw !important;
      }
      .fixed {
        height: 285vh !important;
          .fixed_coupon {
          width: 60%!important;
        }
      }
      ::-webkit-scrollbar{
        width: 30px !important;
        background-color:transparent !important;
        display: block !important;
      }
      ::-webkit-scrollbar-thumb        { 
        border-radius:10px;
        background-color:#ccc;
       }
       ::-webkit-scrollbar-track{
          border-radius:10px;
          background-color:#F5F5F5;
      }
      .m-scroll::-webkit-scrollbar {
        width: 30px !important;
        background-color: transparent!important;
        display: block !important; /* Chrome Safari */
      }
      .sub-item {
        margin: 0.5vw 3.333vw !important;
      }
      .m-goods-box:nth-child(odd) {
        margin-right: 0.75vw !important;
      }
      .loginBtn {
        bottom: 24vw !important;
      }
      html {
        background: url("../src/assets/Frame2.png");
        background-size: 25%;
      }
      .isPc {
        height: 100% !important;
        background-color: #fff !important;
         .m-index {
            height: (100% - 16vw)  !important;
            padding-bottom: 0 !important;
         }
      }
      .van-list {
        p {
          font-size: 50px !important;
        }
      }
      .m-goods-columns .goods-columns-2 {
        width: 42.8vw !important;
        img {
          width: 42.8vw !important;
          height: 42.8vw !important;
        }
        .eleven  {
          width: 42.8vw !important;
          height: 42.8vw !important;
        }
      }
      .showShare {
        display: none;
      }
      .m-goods-columns .goods-columns-2 .goods-price > img {
        width: 10.66667vw !important;
        height: 10.66667vw !important;
      }
      .m-goods-columns .goods-columns-2 .goods-price > .eleven {
        width: 10.66667vw !important;
        height: 10.66667vw !important;
      }
      .van-switch {
        font-size: 55px !important;
        top: -20px;
      }
      .richmondpc {
        .main {
          height: 280vh !important;
        }
      }
      .m-login {
        .main {
          height: 125vh !important;
        }
        .code {
          input {
            color: #333  !important;
            font-size: 4.8vw  !important;
            padding: 0 3.73333vw  !important;
            letter-spacing: 13.33333vw !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none  !important;
          }
          input[type="number"]{
            -moz-appearance: textfield;
          }
          .input-view {
            color: transparent  !important;
          }
        }
      }
  } 
</style>
<style lang="less" scoped>
.van-overlay-load {overflow: hidden;
  z-index: 999;backdrop-filter: saturate(180%) blur(15px);background-color: transparent;
  .load-bg{position: absolute;top:-20%;left:-20%;width:140%;height:140%;
    background-color: rgba(114, 114, 114, 0.452);
    filter: blur(16px);
    -o-filter: blur(16px);
    -ms-filter: blur(16px);
    -moz-filter: blur(16px);
    -webkit-filter: blur(16px);
  }
  .load-mooby{display: block !important;}
  .pswp__preloader__donut{border-color: #fff !important;}
}
.van-loading__text {
  color: #df5853;
}
.view-loading{display: block !important;opacity: 1 !important}


.beta-box{position: fixed;top:0;left:0;width:100%;height:100%;background-color: rgba(17, 17, 17, 0.452);z-index: 999999999;
  img{width:341px;position: absolute;top:50%;left:50%;transform: translate(-50%, -50%);z-index: 999;}
}
</style>


